let countryNodeID;
let langurageData;
console.log('config.js - process.env.LANG', process.env.LANG);
switch(process.env.LANG){ //'tw' :localhost
    case 'tw':
        countryNodeID = '919AB72486624AA5A521A16FF014140B';
        langurageData = [{
            name: '繁中',
            type: 'zh-TW',
            bookingName: '分店資訊',
            onlinebookingName: '線上訂位',
            option: {'store': '請選擇分店', 'other': '請選擇',},
            emailHead:{
                'giftcards': 'From Official website-Gift Cards', 
                'privateevents': 'From Official website-Private Events',
                'member': 'From Official website-Member',
                'contactus': "From Ruth's Chris Taiwan Official website",
            },
            fb: 'https://www.facebook.com/ruthschris.tw/',
            ig: 'https://www.instagram.com/ruthschristw/',
            country: process.env.LANG,
            memberAlert: '請勾選同意「隱私權與個人資料保護政策」'
        }, {
            name: 'EN',
            type: 'en-US',
            bookingName: 'STORE INFORMATION',
            onlinebookingName: 'ONLINE BOOKING',
            option: {'store': 'Choose Location', 'other': 'Please Select',},
            emailHead:{
                'giftcards': 'From Official website-Gift Cards', 
                'privateevents': 'From Official website-Private Events',
                'member': 'From Official website-Member',
                'contactus': "From Ruth's Chris Taiwan Official website",
            },
            fb: 'https://www.facebook.com/ruthschris.tw/',
            ig: 'https://www.instagram.com/ruthschristw/',
            country: process.env.LANG,
            memberAlert: 'Please check the privacy policy and personal information protection notification'
        }];
        break;
    case 'hk':
        countryNodeID = '1BCB6B48E9D8411C9973A966C5FF8EA2';
        langurageData = [{
            name: 'EN',
            type: 'en-US',
            bookingName: 'RESERVATIONS',
            option: {'store': 'Choose Location', 'other': 'Please Select',},
            emailHead:{
                'giftcards': 'From Official website-Gift Cards', 
                'privateevents': 'From Official website-Private Events',
                'member': 'From Official website-Member',
                'contactus': "From Ruth's Chris Hong Kong Official website",
            },
            fb: 'https://www.facebook.com/RuthsChrisSteakHouseHK',
            ig: '',
            country: process.env.LANG,
            memberAlert: 'Please check the privacy policy and personal information protection notification'
        }];
        break;
    case 'jp':
        countryNodeID = '358D7623AB3849109CBC39652EEBB70F';
        langurageData = [{
            name: '日本語',
            type: 'jp',
            bookingName: '予約',
            option: {'store': '店舗を選択してください。', 'other': '選択してください。',},
            emailHead:{
                'giftcards': 'From Official website-Gift Cards', 
                'privateevents': 'From Official website-Private Events',
                'member': 'From Official website-Member',
                'contactus': "From Ruth's Chris Japan Official website",
            },
            fb: '',
            ig: '',
            country: process.env.LANG,
            memberAlert: '請勾選同意「隱私權與個人資料保護政策」'
        }, {
            name: 'EN',
            type: 'en-US',
            bookingName: 'RESERVATIONS',
            option: {'store': 'Choose Location', 'other': 'Please Select',},
            emailHead:{
                'giftcards': 'From Official website-Gift Cards', 
                'privateevents': 'From Official website-Private Events',
                'member': 'From Official website-Member',
                'contactus': "From Ruth's Chris Japan Official website",
            },
            fb: '',
            ig: '',
            country: process.env.LANG,
            memberAlert: 'Please check the privacy policy and personal information protection notification'
        }];
        break;
    case 'sg':
        countryNodeID = 'C6545B60CC464421B8B116D2E653F872';
        langurageData = [{
            name: 'EN',
            type: 'en-US',
            bookingName: 'RESERVATIONS',
            option: {'store': 'Choose Location', 'other': 'Please Select',},
            emailHead:{
                'giftcards': 'From Official website-Gift Cards', 
                'privateevents': 'From Official website-Private Events',
                'member': 'From Official website-Member',
                'contactus': "From Ruth's Chris Singapore Official website",
            },
            fb: 'https://www.facebook.com/RuthsChrisSteakHouseSG',
            ig: '',
            country: process.env.LANG,
            memberAlert: 'Please check the privacy policy and personal information protection notification'
        }];
        break;
    case 'id':
        countryNodeID = 'A6BC1D35E2494D4C924367591747B99C';
        langurageData = [{
            name: '印尼',
            type: 'id',
            bookingName: '訂位資訊',
            option: {'store': '請選擇分店', 'other': '請選擇',},
            emailHead:{
                'giftcards': 'From Official website-Gift Cards', 
                'privateevents': 'From Official website-Private Events',
                'member': 'From Official website-Member',
                'contactus': "From Ruth's Chris Indonesia Official website",
            },
            fb: 'https://www.facebook.com/RuthsChrisSteakHouseJKT',
            ig: '',
            country: process.env.LANG,
            memberAlert: '請勾選同意「隱私權與個人資料保護政策」'
        }, {
            name: 'EN',
            type: 'en-US',
            bookingName: 'RESERVATIONS',
            option: {'store': 'Choose Location', 'other': 'Please Select',},
            emailHead:{
                'giftcards': 'From Official website-Gift Cards', 
                'privateevents': 'From Official website-Private Events',
                'member': 'From Official website-Member',
                'contactus': "From Ruth's Chris Indonesia Official website",
            },
            fb: 'https://www.facebook.com/RuthsChrisSteakHouseJKT',
            ig: '',
            country: process.env.LANG,
            memberAlert: 'Please check the privacy policy and personal information protection notification'
        }];
        break;
    case 'ph':
        countryNodeID = 'E31E3A7250AC45DEBBBF4C01EA33E1A1';
        langurageData = [{
            name: 'EN',
            type: 'en-US',
            bookingName: 'RESERVATIONS',
            option: {'store': 'Choose Location', 'other': 'Please Select',},
            emailHead:{
                'giftcards': 'From Official website-Gift Cards', 
                'privateevents': 'From Official website-Private Events',
                'member': 'From Official website-Member',
                'contactus': "From Ruth's Chris Philippines Official website",
            },
            fb: '',
            ig: '',
            country: process.env.LANG,
            memberAlert: 'Please check the privacy policy and personal information protection notification'
        }];
        break;
};
//console.log('config.js - countryNodeID', countryNodeID);
//console.log('config.js - langurageData', langurageData);
let APPCONFIG = {
    countryID: process.env.COUNTRY || countryNodeID, ///new
    //countryID: process.env.COUNTRY || '919AB72486624AA5A521A16FF014140B', ///tw
    // countryID: process.env.COUNTRY || '1BCB6B48E9D8411C9973A966C5FF8EA2', ///hk
    // countryID: process.env.COUNTRY || '358D7623AB3849109CBC39652EEBB70F', ///jp
    // countryID: process.env.COUNTRY || 'C6545B60CC464421B8B116D2E653F872', ///sg
    // countryID: process.env.COUNTRY || 'A6BC1D35E2494D4C924367591747B99C', ///id
    // countryID: process.env.COUNTRY || 'E31E3A7250AC45DEBBBF4C01EA33E1A1', ///ph
    // BackEndWebApi: 'http://40.74.70.104:9995/',
    //BackEndWebApi: 'http://104.42.40.253:9995/',
    // BackEndWebApi: 'http://180.218.64.109:5000',
    //BackEndWebApi: 'http://localhost:8007/',
    // BackEndWebApi: 'http://104.42.40.253/RuthschrisApi/',
    BackEndWebApi: 'https://www.ruthschris.com.tw/Api', // buildurl
    // BackEndWebApi: 'http://184.168.121.140/RuthschrisApi/', // UAT
    // BackEndWebApi: 'http://localhost:2695/', //local test
    intervalsTime: 10000,
};
console.log('config.js - process.env.COUNTRY', process.env.COUNTRY);
//console.log('config.js - APPCONFIG', APPCONFIG);
switch (process.env.COUNTRY) {
    case '919AB72486624AA5A521A16FF014140B': ///tw
    // case '1BCB6B48E9D8411C9973A966C5FF8EA2': ///hk
    // case '358D7623AB3849109CBC39652EEBB70F': ///jp
    // case 'C6545B60CC464421B8B116D2E653F872': ///sg
    // case 'A6BC1D35E2494D4C924367591747B99C': ///id
    // case 'E31E3A7250AC45DEBBBF4C01EA33E1A1': ///ph
        APPCONFIG = {
            ...APPCONFIG,
            ///tw
            langurage: [{
                title: "Ruth's Chris Steak House",
                url: 'https://www.ruthschris.com.tw',
                // imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
                imageUrl:'https://www.ruthschris.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png', // buildurl
                description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
                keyWord: 'Ruth’s Chris,茹絲葵',
                seoScript: 'SEO',
                name: '繁中',
                type: 'zh-TW',
                bookingName: '分店資訊',
                onlinebookingName: '線上訂位',
                email: 'marketing@hasmoreltd.com',
                send: '成功寄出',
                goBackFood: '回到食物菜單',
                goBack: '回到特別活動',
                next: '下一頁',
                previous: '上一頁',
            }, {
                title: "Ruth's Chris Steak House",
                url: 'https://www.ruthschris.com.tw',
                // imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
                imageUrl:'https://www.ruthschris.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png', // buildurl
                description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
                keyWord: 'Ruth’s Chris,茹絲葵',
                seoScript: 'SEO',
                name: 'EN',
                type: 'en-US',
                bookingName: 'STORE INFORMATION',
                onlinebookingName: 'ONLINE BOOKING',
                email: 'marketing@hasmoreltd.com',
                send: 'send success',
                goBackFood: 'BACK TO DINNER MENU',
                goBack: 'BACK TO PROMOTIONS',
                next: 'next',
                previous: 'previous',
            }],

            ///hk
            // langurage: [{
            //     title: "Ruth's Chris Steak House",
            //     url: 'https://www.ruthschris.com.tw',
            //     imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
            //     description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
            //     keyWord: 'Ruth’s Chris,茹絲葵',
            //     seoScript: 'SEO',
            //     name: 'EN',
            //     type: 'en-US',
            //     bookingName: 'RESERVATIONS',
            //     email: 'marketing@hasmoreltd.com',
            //     fb: 'https://www.facebook.com/ruthschris.tw/?fref=ts',
            //     ig: 'https://reurl.cc/gvRqbQ',
            //     send: 'send success',
            //     goBackFood: 'BACK TO DINNER MENU',
            //     goBack: 'BACK TO PROMOTIONS',
            //     next: 'next',
            //     previous: 'previous',
            // }],

            ///jp
            // langurage: [{
            //     title: "Ruth's Chris Steak House",
            //     url: 'https://www.ruthschris.com.tw',
            //     imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
            //     description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
            //     keyWord: 'Ruth’s Chris,茹絲葵',
            //     seoScript: 'SEO',
            //     name: '日文',
            //     type: 'jp',
            //     bookingName: '訂位資訊',
            //     email: 'marketing@hasmoreltd.com',
            //     fb: 'https://www.facebook.com/ruthschris.tw/?fref=ts',
            //     ig: 'https://reurl.cc/gvRqbQ',
            //     send: '成功寄出',
            //     goBackFood: '回到食物菜單',
            //     goBack: '回到特別活動',
            //     next: '下一頁',
            //     previous: '上一頁',
            // }, {
            //     title: "Ruth's Chris Steak House",
            //     url: 'https://www.ruthschris.com.tw',
            //     imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
            //     description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
            //     keyWord: 'Ruth’s Chris,茹絲葵',
            //     seoScript: 'SEO',
            //     name: 'EN',
            //     type: 'en-US',
            //     bookingName: 'RESERVATIONS',
            //     email: 'marketing@hasmoreltd.com',
            //     fb: 'https://www.facebook.com/ruthschris.tw/?fref=ts',
            //     ig: 'https://reurl.cc/gvRqbQ',
            //     send: 'send success',
            //     goBackFood: 'BACK TO DINNER MENU',
            //     goBack: 'BACK TO PROMOTIONS',
            //     next: 'next',
            //     previous: 'previous',
            // }],

            ///sg
            // langurage: [{
            //     title: "Ruth's Chris Steak House",
            //     url: 'https://www.ruthschris.com.tw',
            //     imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
            //     description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
            //     keyWord: 'Ruth’s Chris,茹絲葵',
            //     seoScript: 'SEO',
            //     name: 'EN',
            //     type: 'en-US',
            //     bookingName: 'RESERVATIONS',
            //     email: 'marketing@hasmoreltd.com',
            //     fb: 'https://www.facebook.com/ruthschris.tw/?fref=ts',
            //     ig: 'https://reurl.cc/gvRqbQ',
            //     send: 'send success',
            //     goBackFood: 'BACK TO DINNER MENU',
            //     goBack: 'BACK TO PROMOTIONS',
            //     next: 'next',
            //     previous: 'previous',
            // }],

            ///id
            // langurage: [{
            //     title: "Ruth's Chris Steak House",
            //     url: 'https://www.ruthschris.com.tw',
            //     imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
            //     description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
            //     keyWord: 'Ruth’s Chris,茹絲葵',
            //     seoScript: 'SEO',
            //     name: '印尼',
            //     type: 'id',
            //     bookingName: '訂位資訊',
            //     email: 'marketing@hasmoreltd.com',
            //     fb: 'https://www.facebook.com/ruthschris.tw/?fref=ts',
            //     ig: 'https://reurl.cc/gvRqbQ',
            //     send: '成功寄出',
            //     goBackFood: '回到食物菜單',
            //     goBack: '回到特別活動',
            //     next: '下一頁',
            //     previous: '上一頁',
            // }, {
            //     title: "Ruth's Chris Steak House",
            //     url: 'https://www.ruthschris.com.tw',
            //     imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
            //     description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
            //     keyWord: 'Ruth’s Chris,茹絲葵',
            //     seoScript: 'SEO',
            //     name: 'EN',
            //     type: 'en-US',
            //     bookingName: 'RESERVATIONS',
            //     email: 'marketing@hasmoreltd.com',
            //     fb: 'https://www.facebook.com/ruthschris.tw/?fref=ts',
            //     ig: 'https://reurl.cc/gvRqbQ',
            //     send: 'send success',
            //     goBackFood: 'BACK TO DINNER MENU',
            //     goBack: 'BACK TO PROMOTIONS',
            //     next: 'next',
            //     previous: 'previous',
            // }],

            ///ph
            // langurage: [{
            //     title: "Ruth's Chris Steak House",
            //     url: 'https://www.ruthschris.com.tw',
            //     imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
            //     description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
            //     keyWord: 'Ruth’s Chris,茹絲葵',
            //     seoScript: 'SEO',
            //     name: 'EN',
            //     type: 'en-US',
            //     bookingName: 'RESERVATIONS',
            //     email: 'marketing@hasmoreltd.com',
            //     fb: 'https://www.facebook.com/ruthschris.tw/?fref=ts',
            //     ig: 'https://reurl.cc/gvRqbQ',
            //     send: 'send success',
            //     goBackFood: 'BACK TO DINNER MENU',
            //     goBack: 'BACK TO PROMOTIONS',
            //     next: 'next',
            //     previous: 'previous',
            // }],
        }
        break;
    case '1BCB6B48E9D8411C9973A966C5FF8EA2': ///hk
        APPCONFIG = {
            ...APPCONFIG,
            ///hk
            langurage: [{
                title: "Ruth's Chris Steak House",
                url: 'https://www.ruthschris.com.tw',
                // imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
                imageUrl:'https://www.ruthschris.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png', // buildurl
                description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
                keyWord: 'Ruth’s Chris,茹絲葵',
                seoScript: 'SEO',
                name: 'EN',
                type: 'en-US',
                bookingName: 'RESERVATIONS',
                email: 'marketing@hasmoreltd.com',
                send: 'send success',
                goBackFood: 'BACK TO DINNER MENU',
                goBack: 'BACK TO PROMOTIONS',
                next: 'next',
                previous: 'previous',
            }],
        }
        break;
    case '358D7623AB3849109CBC39652EEBB70F': ///jp
        APPCONFIG = {
            ...APPCONFIG,
            ///jp
            langurage: [{
                title: "Ruth's Chris Steak House",
                url: 'https://www.ruthschris.com.tw',
                // imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
                imageUrl:'https://www.ruthschris.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png', // buildurl
                description: 'ルースクリスはアメリカ中西部のプライムビーフを厳選されています。',
                keyWord: 'Ruth’s Chris；ルースクリス',
                seoScript: 'SEO',
                name: '日本語',
                type: 'jp',
                bookingName: '予約',
                email: 'marketing@hasmoreltd.com',
                send: '送信',
                goBackFood: 'メニューへ',
                goBack: 'イベントへ',
                next: '次に進む',
                previous: '戻る',
            }, {
                title: "Ruth's Chris Steak House",
                url: 'https://www.ruthschris.com.tw',
                // imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
                imageUrl:'https://www.ruthschris.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png', // buildurl
                description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
                keyWord: 'Ruth’s Chris,茹絲葵',
                seoScript: 'SEO',
                name: 'EN',
                type: 'en-US',
                bookingName: 'RESERVATIONS',
                email: 'marketing@hasmoreltd.com',
                send: 'send success',
                goBackFood: 'BACK TO DINNER MENU',
                goBack: 'BACK TO PROMOTIONS',
                next: 'next',
                previous: 'previous',
            }],
        }
        break;
    case 'C6545B60CC464421B8B116D2E653F872': ///sg
        APPCONFIG = {
            ...APPCONFIG,
            ///sg
            langurage: [{
                title: "Ruth's Chris Steak House",
                url: 'https://www.ruthschris.com.tw',
                // imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
                imageUrl:'https://www.ruthschris.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png', // buildurl
                description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
                keyWord: 'Ruth’s Chris,茹絲葵',
                seoScript: 'SEO',
                name: 'EN',
                type: 'en-US',
                bookingName: 'RESERVATIONS',
                email: 'marketing@hasmoreltd.com',
                send: 'send success',
                goBackFood: 'BACK TO DINNER MENU',
                goBack: 'BACK TO PROMOTIONS',
                next: 'next',
                previous: 'previous',
            }],
        }
        break;
    case 'A6BC1D35E2494D4C924367591747B99C': ///id
        APPCONFIG = {
            ...APPCONFIG,
            ///id
            langurage: [{
                title: "Ruth's Chris Steak House",
                url: 'https://www.ruthschris.com.tw',
                // imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
                imageUrl:'https://www.ruthschris.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png', // buildurl
                description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
                keyWord: 'Ruth’s Chris,茹絲葵',
                seoScript: 'SEO',
                name: '印尼',
                type: 'id',
                bookingName: '訂位資訊',
                email: 'marketing@hasmoreltd.com',
                send: '成功寄出',
                goBackFood: '回到食物菜單',
                goBack: '回到特別活動',
                next: '下一頁',
                previous: '上一頁',
            }, {
                title: "Ruth's Chris Steak House",
                url: 'https://www.ruthschris.com.tw',
                // imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
                imageUrl:'https://www.ruthschris.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png', // buildurl
                description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
                keyWord: 'Ruth’s Chris,茹絲葵',
                seoScript: 'SEO',
                name: 'EN',
                type: 'en-US',
                bookingName: 'RESERVATIONS',
                email: 'marketing@hasmoreltd.com',
                send: 'send success',
                goBackFood: 'BACK TO DINNER MENU',
                goBack: 'BACK TO PROMOTIONS',
                next: 'next',
                previous: 'previous',
            }],
        }
        break;
    case 'E31E3A7250AC45DEBBBF4C01EA33E1A1': ///ph
        APPCONFIG = {
            ...APPCONFIG,
            ///ph
            langurage: [{
                title: "Ruth's Chris Steak House",
                url: 'https://www.ruthschris.com.tw',
                // imageUrl:'http://ruthschris.shinda.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png',
                imageUrl:'https://www.ruthschris.com.tw/backend/upload/pic/2020-03/202003171439143297864652.png', // buildurl
                description: 'Ruth’s Chris茹絲葵僅選用佔市場總產量不到2%的U.S. Prime牛肉，絕妙滋味歷久彌新。',
                keyWord: 'Ruth’s Chris,茹絲葵',
                seoScript: 'SEO',
                name: 'EN',
                type: 'en-US',
                bookingName: 'RESERVATIONS',
                email: 'marketing@hasmoreltd.com',
                send: 'send success',
                goBackFood: 'BACK TO DINNER MENU',
                goBack: 'BACK TO PROMOTIONS',
                next: 'next',
                previous: 'previous',
            }],
        }
        break;
    default:
        APPCONFIG = {
            ...APPCONFIG,
            ///new
            langurage: [...langurageData],

            ///tw
            // langurage: [{
            //     name: '繁中',
            //     type: 'zh-TW',
            //     bookingName: '訂位資訊',
            //     option: {'store': '請選擇分店', 'other': '請選擇',},
            //     emailHead:{
            //         'giftcards': 'From Official website-Gift Cards', 
            //         'privateevents': 'From Official website-Private Events',
            //         'member': 'From Official website-Member',
            //         'contactus': 'From Official website-Contact Us',
            //     },
            // }, {
            //     name: 'EN',
            //     type: 'en-US',
            //     bookingName: 'RESERVATIONS',
            //     option: {'store': 'Choose Location', 'other': 'Please Select',},
            //     emailHead:{
            //         'giftcards': 'From Official website-Gift Cards', 
            //         'privateevents': 'From Official website-Private Events',
            //         'member': 'From Official website-Member',
            //         'contactus': 'From Official website-Contact Us',
            //     },
            // }],

            ///hk
            // langurage: [{
            //     name: 'EN',
            //     type: 'en-US',
            //     bookingName: 'RESERVATIONS',
            //     option: {'store': 'Choose Location', 'other': 'Please Select',},
            //     emailHead:{
            //         'giftcards': 'From Official website-Gift Cards', 
            //         'privateevents': 'From Official website-Private Events',
            //         'member': 'From Official website-Member',
            //         'contactus': 'From Official website-Contact Us',
            //     },
            // }],

            ///jp
            // langurage: [{
            //     name: '日文',
            //     type: 'jp',
            //     bookingName: '訂位資訊',
            //     option: {'store': '請選擇分店', 'other': '請選擇',},
            //     emailHead:{
            //         'giftcards': 'From Official website-Gift Cards', 
            //         'privateevents': 'From Official website-Private Events',
            //         'member': 'From Official website-Member',
            //         'contactus': 'From Official website-Contact Us',
            //     },
            // }, {
            //     name: 'EN',
            //     type: 'en-US',
            //     bookingName: 'RESERVATIONS',
            //     option: {'store': 'Choose Location', 'other': 'Please Select',},
            //     emailHead:{
            //         'giftcards': 'From Official website-Gift Cards', 
            //         'privateevents': 'From Official website-Private Events',
            //         'member': 'From Official website-Member',
            //         'contactus': 'From Official website-Contact Us',
            //     },
            // }],

            ///sg
            // langurage: [{
            //     name: 'EN',
            //     type: 'en-US',
            //     bookingName: 'RESERVATIONS',
            //     option: {'store': 'Choose Location', 'other': 'Please Select',},
            //     emailHead:{
            //         'giftcards': 'From Official website-Gift Cards', 
            //         'privateevents': 'From Official website-Private Events',
            //         'member': 'From Official website-Member',
            //         'contactus': 'From Official website-Contact Us',
            //     },
            // }],

            ///id
            // langurage: [{
            //     name: '印尼',
            //     type: 'id',
            //     bookingName: '訂位資訊',
            //     option: {'store': '請選擇分店', 'other': '請選擇',},
            //     emailHead:{
            //         'giftcards': 'From Official website-Gift Cards', 
            //         'privateevents': 'From Official website-Private Events',
            //         'member': 'From Official website-Member',
            //         'contactus': 'From Official website-Contact Us',
            //     },
            // }, {
            //     name: 'EN',
            //     type: 'en-US',
            //     bookingName: 'RESERVATIONS',
            //     option: {'store': 'Choose Location', 'other': 'Please Select',},
            //     emailHead:{
            //         'giftcards': 'From Official website-Gift Cards', 
            //         'privateevents': 'From Official website-Private Events',
            //         'member': 'From Official website-Member',
            //         'contactus': 'From Official website-Contact Us',
            //     },
            // }],

            ///ph
            // langurage: [{
            //     name: 'EN',
            //     type: 'en-US',
            //     bookingName: 'RESERVATIONS',
            //     option: {'store': 'Choose Location', 'other': 'Please Select',},
            //     emailHead:{
            //         'giftcards': 'From Official website-Gift Cards', 
            //         'privateevents': 'From Official website-Private Events',
            //         'member': 'From Official website-Member',
            //         'contactus': 'From Official website-Contact Us',
            //     },
            // }],
        }
        break;
}

export default APPCONFIG;
